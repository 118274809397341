
import { defineComponent, onMounted, PropType, ref } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import { Patient } from '@/models/Patient';
import { useRoute } from 'vue-router';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { SurveyType } from '@/models/Survey';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  components: {
    HeadingComponent,
    LoadingComponent,
  },
  emits: ['patient-changed'],
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const { t } = useI18n();
    const tabsMenu = ref([
      { label: t(`personData.medicalHistoryTab`), to: 'medical-history' },
      { label: t(`personData.anthropometricDataTab`), to: 'anthropometric-data' },
      { label: t(`personData.healzObjectiveTab`), to: 'healz-objective' },
      { label: t(`personData.habitsTab`), to: 'habits' },
    ]);
    const surveyCgmInitial = ref();
    const loadingSurvey = ref(true);

    const fetchCgmInitialSurvey = async () => {
      const result = await patientService.findSurvey(props.patient.id, SurveyType.CGM_INITIAL);
      loadingSurvey.value = false;
      if (!(result instanceof ServiceError)) {
        surveyCgmInitial.value = result;
      }
    };

    onMounted(async () => {
      await fetchCgmInitialSurvey();
    });
    return {
      route,
      tabsMenu,
      surveyCgmInitial,
      loadingSurvey,
    };
  },
});
